<template>
  <v-container fluid class="pa-0">
    <edu-landing-section />
    <edu-case-section class="background_normal py-5" id="case" />
    <edu-review-section />
    <edu-content-section class="background_normal" id="contents" />
  </v-container>
</template>

<script>
import EduLandingSection from "./Components/EduLandingSection.vue";
import EduCaseSection from "./Components/EduCaseSection.vue";
import EduReviewSection from "./Components/EduReviewSection.vue";
import EduContentSection from "./Components/EduContentSection.vue";

export default {
  name: "Edu",
  components: {
    EduLandingSection,
    EduCaseSection,
    EduReviewSection,
    EduContentSection,
  },

  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
