<template>
  <v-container fluid class="pb-16">
    <v-card class="transparent" flat>
      <v-card-title class="title font-weight-regular justify-center my-8">
        <span>누구나 상상력을 펼칠 수 있는 다양한 <b>교육 컨텐츠</b></span>
      </v-card-title>
    </v-card>

    <v-container>
      <v-row style="max-width: 1200px" class="ma-auto">
        <v-col
          v-for="item in contents"
          :key="item.title"
          cols="12"
          md="6"
          class="mb-10"
        >
          <div class="d-flex">
            <v-img
              class=""
              style="border-radius: 15%"
              :max-width="$vuetify.breakpoint.smAndUp ? '180' : '140'"
              :max-height="$vuetify.breakpoint.smAndUp ? '180' : '140'"
              :src="require(`../Images/${item.imageName}.jpg`)"
            />

            <v-card class="transparent px-4" style="" flat outlined>
              <v-card-title
                :class="
                  $vuetify.breakpoint.smAndUp
                    ? 'title'
                    : 'text-subtitle-2 font-weight-bold'
                "
              >
                {{ item.title }}
              </v-card-title>

              <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'pb-0'">
                {{ item.content }}
              </v-card-text>
              <div class="ml-4 mt-4">
                <v-chip
                  v-for="(chip, chipIndex) in item.chips"
                  :key="chipIndex"
                  :color="getChipColor(chip)"
                  class="mr-1"
                  style=""
                  outlined
                >
                  {{ chip }}
                </v-chip>
              </div>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      contents: [
        {
          title: "탁상 이름표 만들기",
          content: "탁상에 세울 수 있는 형태의 이름표를 만들어요.",
          imageName: "content1",
          chips: ["초등교육", "고급"],
        },
        {
          title: "둥근 이름표 만들기",
          content: "직접 둥근 모양을 만들고 이름표를 꾸며요.",
          imageName: "content2",
          chips: ["초등교육", "중급"],
        },
        {
          title: "화분 만들기",
          content:
            "어떤 모양이든 상관없어요. 상상력을 발휘해서 직접 화분의 형태를 구상해요.",
          imageName: "content3",
          chips: ["초등교육", "중급"],
        },
        {
          title: "코드 블록",
          content:
            "3D 모델링을 블록 코딩으로 제어해보고 효율적인 알고리즘을 고민해봐요.",
          imageName: "content4",
          chips: ["초등교육", "고급"],
        },
      ],
      chipColors: {
        초등교육: "blue",
        중등교육: "",
        고등교육: "",
        초급: "green",
        중급: "orange",
        고급: "red",
      },
    };
  },
  methods: {
    getChipColor(label) {
      return this.chipColors[label] || "default";
    },
  },
};
</script>

<style></style>
