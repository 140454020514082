<template>
  <v-container fluid class="mx-auto">
    <v-card class="transparent mt-12" style="" flat>
      <v-card-title
        class="justify-center font-weight-regular my-8"
        :class="$vuetify.breakpoint.smAndUp ? 'title' : 'caption'"
      >
        전문가와 함께하는 생생한
        <span style="font-weight: bold">&nbsp;교육 현장 사진</span>입니다.
      </v-card-title>

      <!--desktop, tablet-->
      <v-container
        v-if="$vuetify.breakpoint.mdAndUp"
        class="d-flex align-center justify-center mb-16 mx-auto"
      >
        <v-row
          style="max-width: 1200px; position: relative"
          class="justify-center align-center d-flex mx-auto"
        >
          <span @click="moveLeft" style="left: -70px; position: absolute">
            <v-hover v-slot="{ hover }" close-delay="100">
              <v-icon size="40" :color="hover ? 'primary' : ''">
                mdi-chevron-left
              </v-icon>
            </v-hover>
          </span>

          <span @click="moveRight" style="right: -70px; position: absolute">
            <v-hover v-slot="{ hover }" close-delay="100">
              <v-icon size="40" :color="hover ? 'primary' : ''">
                mdi-chevron-right
              </v-icon>
            </v-hover>
          </span>

          <v-col v-for="item in visibleCards" :key="item.imageName[0]" md="3">
            <v-card
              class="pa-auto ma-auto align-center justify-center"
              width=""
              height=""
              outlined
              flat
              @click="openDialog(item)"
            >
              <v-img
                class="w-100"
                :aspect-ratio="4 / 3"
                height=""
                :src="require(`../Images/EduCase/${item.imageName[0]}.jpg`)"
              />
              <v-card-title class="caption font-weight-normal overflow-hidden">
                {{ item.title }}
              </v-card-title>

              <v-card-subtitle class="caption font-weight-lighter">
                {{ item.date }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!--mobile-->
      <v-container v-else align="center" class="text-center">
        <span @click="moveLeft">
          <v-hover v-slot="{ hover }" close-delay="100">
            <v-icon
              size="30"
              :color="hover ? 'primary' : ''"
              @click="moveLeft"
              class="ma-4"
            >
              mdi-chevron-up
            </v-icon>
          </v-hover>
        </span>
        <v-col
          v-for="item in visibleCards"
          :key="item.imageName[0]"
          cols="12"
          sm="12"
        >
          <v-card class="" outlined flat @click="openDialog(item)">
            <v-img
              style="align-items: center"
              max-height="240"
              :aspect-ratio="2 / 1"
              :src="require(`../Images/EduCase/${item.imageName[0]}.jpg`)"
            >
            </v-img>
          </v-card>
        </v-col>
        <span @click="moveRight">
          <v-hover v-slot="{ hover }" close-delay="100">
            <v-icon size="30" :color="hover ? 'primary' : ''" class="ma-4">
              mdi-chevron-down
            </v-icon>
          </v-hover>
        </span>
      </v-container>
    </v-card>

    <!--modal-->
    <template>
      <v-dialog :retain-focus="false" v-model="dialog" width="80%" scrollable>
        <!-- <template v-slot:activator="{ on }">
              <v-btn v-on="on">Open Dialog</v-btn>
            </template> -->
        <v-card v-if="selectedCard" class="rounded-xl">
          <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              class="onSurface--text"
              @click="closeDialog"
              rounded
              icon
              :ripple="false"
            >
              <feather type="x-circle" />
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-card class="mb-4" flat>
                    <v-img
                      :aspect-ratio="4 / 3"
                      :src="
                        require(`../Images/EduCase/${selectedCard.imageName[0]}.jpg`)
                      "
                    />
                  </v-card>
                  <v-row>
                    <v-col
                      v-for="image in selectedCard.imageName.slice(1)"
                      :key="image"
                      cols="12"
                      md="6"
                    >
                      <v-img
                        :src="require(`../Images/EduCase/${image}.jpg`)"
                        class=""
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row>
                    <v-col cols="4" md="2" align-self="center"> 제목 </v-col>
                    <v-col
                      cols="8"
                      md="10"
                      class="font-weight-bold onSurface--text"
                    >
                      {{ selectedCard.title }}
                    </v-col>
                    <v-col cols="4" md="2" align-self="center"> 날짜 </v-col>
                    <v-col
                      cols="8"
                      md="10"
                      align-self="center"
                      class="font-weight-bold onSurface--text"
                    >
                      {{ selectedCard.date }}
                    </v-col>
                    <v-col cols="4" md="2" align-self="center">
                      교육 내용
                    </v-col>
                    <v-col
                      cols="8"
                      md="10"
                      align-self="center"
                      class="font-weight-bold onSurface--text"
                    >
                      {{ selectedCard.content }}
                    </v-col>
                    <v-col cols="4" md="2" align-self="center">
                      수강 인원
                    </v-col>
                    <v-col
                      cols="8"
                      md="10"
                      align-self="center"
                      class="font-weight-bold onSurface--text"
                    >
                      <!-- {{ selectedCard. }} -->
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </v-container>
</template>

<script>
import cards from "./EduCards.js";

export default {
  components: {},
  data() {
    return {
      cards: cards,
      dialog: false,
      selectedCard: null,
      visibleCardIndex: 0,
      visibleCardNum: 4,
    };
  },
  computed: {
    visibleCards() {
      return this.cards.slice(
        this.visibleCardIndex,
        this.visibleCardIndex + this.visibleCardNum,
      );
    },
  },
  methods: {
    moveLeft() {
      if (this.visibleCardIndex > 0) {
        this.visibleCardIndex--;
      }
    },
    moveRight() {
      if (this.visibleCardIndex < this.cards.length - this.visibleCardNum) {
        this.visibleCardIndex++;
      }
    },
    openDialog(card) {
      this.selectedCard = card;
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.selectedCard = null;
    },
  },
};
</script>
