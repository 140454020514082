<template>
  <v-container class="mx-auto mb-10">
    <v-card class="" flat>
      <v-card-title class="title font-weight-regular justify-center mt-8">
        아이들의 진심어린
        <span style="font-weight: bold">&nbsp;수강 후기</span>
      </v-card-title>
      <v-card-subtitle class="caption text-center">
        솔직한 아이들의 긍정적 반응 100%
      </v-card-subtitle>
      <v-img
        :src="require('../Images/EduReview.png')"
        max-width="1000"
        class="mx-auto"
      >
      </v-img>
    </v-card>
  </v-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
};
</script>
