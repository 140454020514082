<template>
  <v-container fluid class="background pa-0">
    <v-img :src="require('../Images/EduTop.jpg')">
      <v-container class="d-flex imgBackground">
        <v-row class="ma-auto">
          <v-col cols="12">
            <v-card
              class="pt-10 mt-12 mb-8 transparent mx-auto text-center justify-center"
              flat
            >
              <v-card-subtitle
                class="justify-center white--text"
                :class="
                  $vuetify.breakpoint.smAndUp ? 'text-h5' : 'text-subtitle-1'
                "
              >
                <span>자기주도력 ∙ 창의력 ∙ 문제해결 ∙ 자신감</span>
              </v-card-subtitle>
              <v-card-title
                class="justify-center display-2 white--text mt-auto pb-10"
                :class="$vuetify.breakpoint.smAndUp ? 'display-2' : 'text-h4'"
              >
                <span class="font-weight-bold">메이드올 교육 서비스</span>
              </v-card-title>
              <v-card-text
                class="my-5 white--text"
                :class="
                  $vuetify.breakpoint.smAndUp ? 'title' : 'text-subtitle-2'
                "
              >
                <span
                  >메이드올은 학생 스스로 결과물을 만들 수 있도록
                  <br />
                  <span class="blue--text font-weight-bold">
                    3D 모델링부터 알고리즘까지
                  </span>
                  <b>가치있는 교육</b>을 제공합니다.
                </span>
              </v-card-text>
              <v-card-actions class="pb-10 justify-center">
                <v-btn
                  x-large
                  color="primary"
                  :width="150"
                  :height="50"
                  @click="openChat()"
                >
                  출강 신청하기
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
export default {
  data() {
    return {};
  },
  methods: {
    openChat() {
      window.ChannelIO("showMessenger");
    },
  },
};
</script>
