export default 
[
  {
    title: "차세대융합기술교육원",
    date: "2023.8",
    content: "디지털새싹",
    imageName: [
      "2308융기원1-1",
      "2308융기원1-2",
    ],
  },
  {
    title: "차세대융합기술교육원",
    date: "2023.8",
    content: "디지털새싹",
    imageName: [
      "2308융기원2-1",
      "2308융기원2-2",
      "2308융기원2-3",
    ],
  },
  {
    title: "만안청소년수련관",
    date: "2023.2",
    content: "디지털새싹",
    imageName: [
      "2302만안1-1",
      "2302만안1-2",
      "2302만안1-3",
    ],
  },
  {
    title: "만안청소년수련관",
    date: "2023.2",
    content: "디지털새싹",
    imageName: [
      "2302만안2-1",
      "2302만안2-2",
      "2302만안2-3",
    ],
  },
  {
    title: "광교호수중학교",
    date: "2022.12",
    content: "경기교육나눔사업",
    imageName: [
      "2212광교호수중1-1",
      "2212광교호수중1-2",
      "2212광교호수중1-3",
    ],
  },
  {
    title: "서신중학교",
    date: "2022.11",
    content: "경기교육나눔사업",
    imageName: [
      "2211서신중1-1",
      "2211서신중1-2",
    ],
  },
  {
    title: "광교호수중학교",
    date: "2021.12",
    content: "경기교육나눔사업",
    imageName: [
      "2112광교호수중1-1",
      "2112광교호수중1-2",
      "2112광교호수중1-3",
    ],
  },
  {
    title: "동탄목동중학교",
    date: "2021.11",
    content: "경기교육나눔사업",
    imageName: [
      "2111동탄목동중1-1",
      "2111동탄목동중1-2",
    ],
  },
  {
    title: "동탄목동중학교",
    date: "2021.11",
    content: "경기교육나눔사업",
    imageName: [
      "2111동탄목동중2-1",
      "2111동탄목동중2-2",
    ],
  },
];